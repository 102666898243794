import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set } from "firebase/database";
import { getOpenApiAiResponse, wait } from "./data/GlobalFunctions";
import combatStore, {
  startCombatButtonClicked,
} from "./components/combat/combat-store";

//TO-DO:
//---
//This entire page can be broken down into more managable code fitting with its components etc
//It was created as a first proto-type before implementing react
//---
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// Model to use for openAI calls.
const MODEL_NAME = "gpt-4o-mini";
// const MODEL_NAME = "text-davinci-003";

// Initialize Firebase
const firebaseConfig = {
  apiKey: process.env.FIREBASE_APIKEY,
  authDomain: "dungeonfysh.firebaseapp.com",
  projectId: "dungeonfysh",
  storageBucket: "dungeonfysh.appspot.com",
  messagingSenderId: "517051389795",
  appId: "1:517051389795:web:ef22fe56520853bfe96556",
  measurementId: "G-2QGH5XC5M2",
  databaseURL:
    "https://dungeonfysh-default-rtdb.europe-west1.firebasedatabase.app/",
};
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

//How many times to retry when getting errors (mostly for API calls)
const maxErrors = 5;
//How many lines of chat before summarising (functions as a short term memory of sorts)
const chatMemory = 10;
//How many lines we have summarised so far
let summarisedLines = 0;
//How many lines of chat to keep after summarising
let keepChatLines = 4;
//Prompt instructions passed with every DM prompt call
const promptInstructions =
  "You are DungeonFysh, an AI DM for Dungeons&Dragons 5e." +
  "Continue the adventure, allowing the player to guide their own actions." +
  "Don't jump to conclusions, ask the player for their actions and decisions, " +
  "asking for rolls when suitable" +
  "Be a firm but fair DM. If the player tries to do something, ensure that it fits the setting." +
  "Ensure D&D5e rules." +
  "Always ask for initiative roll before any combat." +
  "There is one player, a level 3 Champion Fighter.\n" +
  "Respond in 120 words max.";

const description =
  "Your feet crunch through the snow underfoot, along a high mountain path. The trees around you" +
  " are barren, with beads of ice shimmering on the branches like diamonds, twinkling with the reflection of the lilac" +
  " borealis overhead. An icy wind whips around you, creating a hollow rattling sound in the wintery forest around you." +
  " You huff with effort, your breath clouding around you as you pull your travelling cloak tightly around yourself." +
  "\n\n" +
  "According to the merchant that hired you back in Bleakwallow, the mountain village of Whitehold should not be much" +
  " further. You are sure he said that all you needed to do was traverse the mountain path, and that there is a river" +
  " just before the town. You can not miss it. Right? You glance upwards, the moon is now peaking around the top of the" +
  " snow-capped mountain. Night is falling. This is not the worst situation you have ever faced," +
  " but it is certainly not ideal. A body can freeze solid up here if it stops moving for too long. Or so you have heard." +
  "\n\nYou pick up your pace, the cold burning the back of your nose and throat. You trudge around the corner and see" +
  " the bridge across the river. Or at least what remains. Splintered planks have been crushed and scattered beneath" +
  " a landslide of enormous rocks and snow.  From this distance it looks like it might just about be traversable," +
  " despite the icy river rapids rushing between the fallen rocks. In the other direction you must stray from the path" +
  " down towards an area of the river that should be much calmer." +
  "\n\nWhat would you like to do?";
const descriptionSummary =
  "Hired by a merchant in Bleakwallow, The player traversed a high mountain path," +
  " heading towards the village of Whitehold." +
  " With the icy night drawing in they find the bridge to town largely destroyed. They must decide" +
  " whether to try crossing the icy treacherous rapids or take a longer route, off the path, to try to find calmer waters.";
let summary = "";

//Has the user submitted into chat and therefor awaiting a response.
let submitFlag = false;

let feedbackModal;

//This was originally on $(function) - e.g document.ready but IoS loads react components later
//This whole functionality could porbabbly be moved into the chatWindow.js.
export function chatIndex() {
  const submitButton = document.getElementById("submit-button");
  const chatForm = document.getElementById("chat-form");
  const chatHistory = document.getElementById("chat-history");
  const chatInput = document.getElementById("chat-input");

  //Add intro description to page
  const introMessageElement = document.createElement("p");
  introMessageElement.classList.add("intro-message");
  introMessageElement.innerHTML =
    "DungeonFysh: " + description.replace(/\n/g, "<br>");
  chatHistory.appendChild(introMessageElement);

  //Remove form submit for return key and have it call the submit button function below instead
  chatForm.addEventListener("submit", function (e) {
    e.preventDefault();
  });
  chatInput.addEventListener("keyup", async (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      submitButton.click();
    }
  });

  //All kinds of stuff that happens when chat is submitted
  //Summarised as calls to API - ensuring sending correct prompts.
  submitButton.addEventListener("click", async () => {
    //Check for multiple submissions
    if (submitFlag) {
      alert("DungeonFysh is thinking...\n\nPlease wait for the DM to respond.");
      return;
    }
    submitFlag = true;

    // Get text from chat input field
    const text = chatInput.value;
    if (text.length > 800) {
      alert("Your input is too long. \n\n Please use 800 maximum characters");
      submitFlag = false;
      return;
    }

    // Clear chat input field
    chatInput.value = "";

    // Add user's input to chat history
    const userMessageElement = document.createElement("p");
    userMessageElement.classList.add("player-message");
    if (combatStore.inCombat) {
      userMessageElement.classList.add("combat-message");
    }
    userMessageElement.textContent = "Player: " + text;
    chatHistory.appendChild(userMessageElement);

    // Scroll chat history to the bottom
    chatHistory.scrollTop = chatHistory.scrollHeight;

    //let variables here to ensure we awaits result
    let newPrompt;
    let apiResponse;

    newPrompt = await tryGeneratePrompt();
    apiResponse = await tryGetDmResponse(newPrompt);

    // Add API response to chat history
    const apiMessageElement = document.createElement("p");
    apiMessageElement.classList.add("api-message");
    if (combatStore.inCombat) {
      apiMessageElement.classList.add("combat-message");
    }
    //TO-DO: Checks here about appropriate response
    apiMessageElement.textContent = "DungeonFysh: " + apiResponse;
    // Create and add feedback button the DM response
    const feedbackButton = document.createElement("button");
    feedbackButton.classList.add("feedback-button");
    feedbackButton.textContent = "Leave Feedback";
    // Add event listener to feedback button and pass the prompt and response
    feedbackButton.addEventListener("click", (event) => {
      event.preventDefault();
      openFeedbackModal(apiResponse, newPrompt);
    });

    apiMessageElement.appendChild(feedbackButton);
    chatHistory.appendChild(apiMessageElement);

    await wait(100);

    //Check if combat should begin
    if (apiResponse.toLowerCase().includes("initiative")) {
      const msessageElement = document.createElement("p");
      msessageElement.classList.add("combat-message");
      msessageElement.classList.add("api-message");
      msessageElement.classList.add("combat-start-message");
      let combatCheckMessage =
        "DungeonFysh: It looks like combat might be about to start " +
        "If this is correct, please confirm by clicking the start combat button. " +
        "Otherwise, you can continue playing as normal and ignore the button.";
      msessageElement.textContent = combatCheckMessage;

      let combatStartButton = document.createElement("button");
      combatStartButton.classList.add("combat-start-button");
      combatStartButton.textContent = "Start combat";
      combatStartButton.addEventListener("click", (event) => {
        startCombatButtonClicked();
        combatStartButton.disabled = true;
      });
      msessageElement.appendChild(combatStartButton);
      chatHistory.appendChild(msessageElement);
    }

    // Scroll chat history to the bottom
    chatHistory.scrollTop = chatHistory.scrollHeight;
    submitFlag = false;
  });
}

//Function to generate prompt formatted for DungeonFysh
//Also handles summary of text
async function generatePrompt() {
  if (!combatStore.inCombat) {
    //As long as we are not in combat - send normal prompt
    let chatHistoryText = "";
    let historyElements = document.querySelectorAll("#chat-history > p");
    //filter out any combat related chat history
    historyElements = Array.from(historyElements).filter(
      (element) => !element.classList.contains("combat-message")
    );
    //Check if chat is getting too long (potentially since last summary)
    //TO-DO: All this summary related code could go into its own function to tidy it up
    if (historyElements.length - summarisedLines >= chatMemory) {
      //If we have not done any summaries yet (i.e first summary)
      if (summary === "") {
        //Summarise everything but the last `keepChatLines` lines of text
        //Ignoring long intro (indexing from 1) and passing summary of intro instead.
        chatHistoryText += descriptionSummary;
        for (let i = 1; i < historyElements.length - keepChatLines; i++) {
          chatHistoryText += historyElements[i].textContent + "\\n";
        }
        //Remove leave feedback button text
        chatHistoryText = chatHistoryText.replace(/Leave Feedback/g, "");
        summary = await summariseText(chatHistoryText);
        if (summary === "" || null) {
          throw new Error("Summary is empty");
        }
        //Update how many lines we have summarised
        summarisedLines = historyElements.length - keepChatLines;
      }
      //Chat is getting long and we have a previous summary to handle
      else {
        //Summarise summary + chat, leaving keepChatLines of chat
        //chatHistoryText = historyElements(all chat) from last summary to end not grabbing keepChatLines
        for (
          let i = summarisedLines - 1;
          i < historyElements.length - keepChatLines;
          i++
        ) {
          chatHistoryText += historyElements[i].textContent + "\\n";
          summarisedLines++;
        }
        //Remove leave feedback button text
        chatHistoryText = chatHistoryText.replace(/Leave Feedback/g, "");
        summary = await summariseText(summary + chatHistoryText);
        if (summary === "" || null) {
          throw new Error("Summary is empty");
        }
      }

      let messages = [
        { role: "system", content: promptInstructions },
        { role: "system", content: summary },
      ];
      getXLastChatLines(keepChatLines).forEach((message) => {
        messages.push(message);
      });

      return messages;
    }
    //Else we are not summarising
    else {
      //If no summary yet
      if (summarisedLines === 0) {
        let messages = [
          { role: "system", content: promptInstructions },
          { role: "system", content: descriptionSummary },
        ];

        //Index after description
        for (let i = 1; i < historyElements.length; i++) {
          if (historyElements[i].classList.contains("player-message")) {
            messages.push({
              role: "user",
              content: historyElements[i].textContent,
            });
          } else if (historyElements[i].classList.contains("api-message")) {
            historyElements[i].textContent.replace(/Leave Feedback/g, "");
            messages.push({
              role: "assistant",
              content: historyElements[i].textContent,
            });
          } else {
            throw new console.error("Error: Unknown message class");
          }
        }
        return messages;
      }

      //(Else) We have summarised at least once
      let messages = [
        { role: "system", content: promptInstructions },
        { role: "system", content: summary },
      ];
      //Get chatHistoryText after last summarised line to end
      for (let i = summarisedLines - 1; i < historyElements.length; i++) {
        if (historyElements[i].classList.contains("player-message")) {
          messages.push({
            role: "user",
            content: historyElements[i].textContent,
          });
        } else if (historyElements[i].classList.contains("api-message")) {
          historyElements[i].textContent.replace(/Leave Feedback/g, "");
          messages.push({
            role: "assistant",
            content: historyElements[i].textContent,
          });
        } else {
          throw new console.error("Error: Unknown message class");
        }
        chatHistoryText += historyElements[i].textContent + "\\n";
      }

      //Return correctly formatted prompt when no summary
      return messages;
    }
  } else {
    let inCombatPromptText =
      "You are DungeonFysh, an AI DM for D&D 5e. A player has sent a query to you." +
      "Currently the player is in combat. Combat is handled by an automated system." +
      "You do not have any control over the combat system or understand it, it is outside of your programming." +
      "At this time you can only respond to queries the player has regarding standard D&D rules." +
      "It is important to bare in mind that the combat system is a basic prototype and not fully complete," +
      "so when answering questions you may need to tell the player that you can't help them." +
      "You can refer the player to 'combat' text in the 'read this first' section.";

    //Get the last `keepChatLines` of chat
    let messages = [{ role: "system", content: inCombatPromptText }];
    getXLastChatLines(4).forEach((message) => {
      messages.push(message);
    });

    return messages;
  }
}

//Function to summarise text
async function summariseText(text) {
  let summaryPrompt = [
    {
      role: "system",
      content:
        "You condense and summarise key story points and information from text in ." +
        "a D&D game." +
        "Aim for 150 words max",
    },
    { role: "user", content: "Summarise the following: " + text },
  ];
  return await getOpenApiAiResponse(summaryPrompt, 0.1, 200, MODEL_NAME);
}

//Function for getting DM responses from openAI
async function getDmResponse(prompt) {
  return await getOpenApiAiResponse(prompt, 0.53, 200, MODEL_NAME);
}

//Function to write email data to database
function writeEmailData(name, email) {
  if (name !== "" && email !== "") {
    set(ref(database, "EmailList/" + name + " - " + Date.now()), {
      email: email,
      name: name,
    });
    return true;
  }
}

//Function to write feedback data to database
export function writeFeedbackData(desiredCompletion, feedbackText, category) {
  set(ref(database, "PromptFeedback/" + category + " - " + Date.now()), {
    category: category,
    originalPrompt: feedbackModal.getAttribute("prompt"),
    actualCompletion: feedbackModal.getAttribute("apiCompletion"),
    desiredCompletion: desiredCompletion,
    feedback: feedbackText,
  });
  // Clear the feedback form
  document.getElementById("feedback-form").reset();
  //Hide the feedback modal
  feedbackModal.style.display = "none";
  feedbackModal.classList.remove("active");
  alert(
    "Thanks for being a hero.\n\nDungeonFysh will take a look at your feedback when its not too busy learning how to be its best DM self ;)"
  );
  return true;
}

//Function to open the feedback modal (also passes along data for potential feedback)
function openFeedbackModal(passedApiResponse, passedPrompt) {
  feedbackModal.style.display = "flex";
  feedbackModal.classList.add("active");
  feedbackModal.setAttribute("apiCompletion", passedApiResponse);
  feedbackModal.setAttribute("prompt", passedPrompt);
}

//Gets the last X lines of chat
function getXLastChatLines(X) {
  var chatLines = [];
  const chatElements = document.querySelectorAll("#chat-history > p");
  for (let i = chatElements.length - X; i < chatElements.length; i++) {
    if (chatElements[i].classList.contains("player-message")) {
      chatLines.push({ role: "user", content: chatElements[i].textContent });
    } else if (chatElements[i].classList.contains("api-message")) {
      chatElements[i].textContent.replace(/Leave Feedback/g, "");
      chatLines.push({
        role: "assistant",
        content: chatElements[i].textContent,
      });
    } else {
      throw new console.error("Error: Unknown message class");
    }
  }
  return chatLines;
}

export async function tryGeneratePrompt() {
  let newPrompt;
  try {
    newPrompt = await generatePrompt();
    return newPrompt;
  } catch (error) {
    console.log(error);
    let errorFlag = true;
    let errorCount = 1;
    while (errorFlag && errorCount < maxErrors) {
      alert(
        "Something went wrong...\n\n DungeonFysh will try again.\n\n" +
          "Attempt: " +
          errorCount +
          "/" +
          maxErrors
      );
      errorCount++;
      try {
        newPrompt = await generatePrompt();
        errorFlag = false;
        return newPrompt;
      } catch (e) {
        //Still error, try again (continue while loop)
      }
    }
    if (errorCount === maxErrors) {
      alert(
        "It broke! :(\n\nDugeonFysh will try better next time\n\nPlease reload or close the page."
      );
      return "System...failure...I'll try better... next time...";
    }
  }
}

export async function tryGetDmResponse(prompt) {
  let apiResponse;
  try {
    apiResponse = await getDmResponse(prompt);
    return apiResponse;
  } catch (error) {
    console.debug(error);
    let errorFlag = true;
    let errorCount = 1;
    while (errorFlag && errorCount < maxErrors) {
      console.log(error);
      alert(
        "Something went wrong getting a response...\n\n DungeonFysh will try again.\n\n" +
          "Attempt: " +
          errorCount +
          "/" +
          maxErrors
      );
      errorCount++;
      try {
        apiResponse = await getDmResponse(prompt);
        errorFlag = false;
        return apiResponse;
      } catch (e) {
        console.debug(e);
        //Still an error, try again (continue while loop)
      }
    }
    if (errorCount === maxErrors) {
      alert(
        "It broke! :(\n\nDugeonFysh will try better next time\n\nPlease reload or close the page."
      );
      return "System...failure...I'll try better... next time...";
    }
  }
}

//This can probabbly be re-worked into the overlay.js component
export function SignUpOverlayIndex() {
  const signupButton = document.getElementById("signup-button");
  const closeButton = document.getElementById("close-button");
  const overlay = document.getElementById("overlay");
  const submitBtn = document.getElementById("submitEmailButton");

  //Show Overlay when clicking to sign up
  signupButton.addEventListener("click", function () {
    overlay.style.display = "block";
    overlay.classList.add("active");
    submitBtn.disabled = false;
  });

  //Submit email to database and close overlay, if passing validation checks.
  document
    .getElementById("signup-form")
    .addEventListener("submit", function (event) {
      event.preventDefault();
      const name = document.getElementById("signupName").value;
      const email = document.getElementById("signupEmail").value;
      // Validate name
      if (name.length === 0) {
        alert("Please enter your name.");
        return;
      }
      // Validate email address
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (email.length === 0) {
        alert("Please enter your email address.");
        return;
      } else if (!emailRegex.test(email)) {
        alert("Please enter a valid email address.");
        return;
      }
      submitBtn.disabled = true;

      if (writeEmailData(name, email)) {
        alert("Email successfully submitted.\nGive yourself a high-five!");
      }
      overlay.style.display = "none";
      overlay.classList.remove("active");
    });

  //Close overlay - no submission
  closeButton.addEventListener("click", function () {
    overlay.style.display = "none";
    overlay.classList.remove("active");
  });
}

//This can probabbly be re-worked into the feedback-modal.js component
export function FeedBackModalinIndex() {
  feedbackModal = document.getElementById("feedback-modal");
  const category = document.getElementById("feedback-category");
  const feedbackText = document.getElementById("feedback-text");
  const desiredCompletion = document.getElementById("desired-completion");
  const feedbackClose = document.getElementById("close-feedback-button");
  const submitFeedbackButton = document.getElementById(
    "submit-feedback-button"
  );

  submitFeedbackButton.addEventListener("click", function (e) {
    e.preventDefault();
    writeFeedbackData(
      desiredCompletion.value,
      feedbackText.value,
      category.value
    );
  });
  feedbackClose.addEventListener("click", function (e) {
    e.preventDefault();
    //Hide the feedback modal
    feedbackModal.style.display = "none";
    feedbackModal.classList.remove("active");
  });
}
