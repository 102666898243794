import { useEffect } from "react";
import { chatIndex } from "..";
export function ChatWindow() {
  useEffect(() => {
    // This function will be called when the component has loaded
    chatIndex();
  }, []);

  return (
    <div id="chat-container">
      {/* This div contains chat messages */}
      <div id="chat-history"></div>
      <form id="chat-form">
        <input
          id="chat-input"
          placeholder="Enter your message here..."
          type="text"
        />
        <button id="submit-button" type="button">
          Send
        </button>
      </form>
    </div>
  );
}
