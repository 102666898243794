//This is the store specifically for the resolve-attack-window
//Other compnents make use of probs but this felt complicated as there is allot to keep track off
//Perhaps this or other components could be re-worked in the future to make things more consistent
//But it works for now!
import { observable, action, toJS } from "mobx";
import { Conditions, RollType, Senses, WeaponTypes } from "../../data/Rules";
import { proficientRoll } from "../../data/Rolls";
import combatStore, {
  calculateDistance,
  handleEnemyDeath,
} from "./combat-store";
import { outputToChat } from "../../data/GlobalFunctions";

const resolveAttackStore = observable({
  player: null,
  weapon: null,
  total: null,
  dieRoll: null,
  mod: null,
  enemyTarget: null,
  resolveAttackWindow: null,
  crit: false,
  attackResult: "-",
  damageResult: "-",
  attackDeclared: false,
  attackHit: false,
  damageDealt: false,
  canClose: true,
  rollType: null,
});

//Fired whenever we want to resolve an attack for a player
export const resolveAttack = action((player, weapon) => {
  setPlayer(player);
  setWeapon(weapon);
  //We don't declare the attack here to be nice - instead it is declared when player rolls to attack.
  //However we do reset variables.
  setAttackDeclared(false);
  setAttackHit(false);
  setDamageDealt(false);
  setDamageResult(0);
  setAttackResult("-");
  setRollType(RollType.Normal);
  setCrit(false);

  //Is this a hack? Can't find another way to make it work.
  resolveAttackStore.resolveAttackWindow.props.setResolveAttackVisible(true);

  //Check for finesse
  if (resolveAttackStore.weapon.Type.includes(WeaponTypes.Finesse)) {
    resolveAttackStore.mod = Math.max(
      resolveAttackStore.player.DexMod,
      resolveAttackStore.player.StrMod
    );
  } else {
    resolveAttackStore.mod = resolveAttackStore.player.StrMod;
  }
});

//Triggered when we click the attack button in the resolve-attack-window component
export const attackButton = action(() => {
  //We are already resolving an attack
  if (resolveAttackStore.attackDeclared) {
    return;
  }
  //Rolling for attack, so keep window open until noted
  setCanClose(false);
  //Roll for attack with clicked weapon
  //Declare attack (don't allow window close untill resolved)
  setAttackDeclared(true);
  //Use 1 action and 1 attack on player as attack has been declared.
  resolveAttackStore.player.useAction(resolveAttackStore.player.useAttack());
  //TO-DO: Add check proficient(assuming true for now) - (compare weapon type against players proficiencies)
  let tempRoll;
  handleAdvantage();
  tempRoll = proficientRoll(
    resolveAttackStore.mod,
    resolveAttackStore.player.proficiencyBonus,
    resolveAttackStore.rollType
  );

  setTotal(tempRoll[0]);
  setCrit(tempRoll[1] >= resolveAttackStore.player.critRange);
  //Check if we hit (or crit, as crit always hits)
  if (
    tempRoll[0] >= resolveAttackStore.enemyTarget.AC ||
    resolveAttackStore.crit
  ) {
    //Handle window settings
    setAttackHit(true);
    setAttackResult(tempRoll[0]);
  }
  //Else we missed
  else {
    setCanClose(true);
    setAttackResult(tempRoll[0] + " misses! \nYou can close this window");
  }
});

//Triggered by damage button is clicked in resolve-attack-window component
export const damageButton = action(() => {
  if (!resolveAttackStore.attackHit || resolveAttackStore.damageDealt) {
    return;
  }
  let damage = 0;
  //RAW UnarmedStrike can't crit - but we just let it, for science(and fun).
  let roll = resolveAttackStore.crit
    ? resolveAttackStore.weapon.DamageDie(resolveAttackStore.player) +
      resolveAttackStore.weapon.DamageDie(resolveAttackStore.player)
    : resolveAttackStore.weapon.DamageDie(resolveAttackStore.player);
  //Always add dex mod for ranged weapons
  if (resolveAttackStore.weapon.Type.includes(WeaponTypes.Ranged)) {
    damage = roll + resolveAttackStore.player.DexMod;
  } else {
    damage = roll + resolveAttackStore.mod;
  }
  resolveAttackStore.enemyTarget.reduceHp(damage);
  outputToChat(
    "You dealt " +
      damage +
      " damage to the " +
      resolveAttackStore.enemyTarget.name +
      " with your " +
      resolveAttackStore.weapon.Name +
      "."
  );
  if (resolveAttackStore.enemyTarget.HP <= 0) {
    handleEnemyDeath(resolveAttackStore.enemyTarget);
  }

  resolveAttackStore.damageResult = damage;
  setDamageDealt(true);
  //Attack has been resolved
  setCanClose(true);
});

//Handle checks for advantage and/or disadvantage
//TO-DO: Store reason and feed this back to player
const handleAdvantage = action(() => {
  //Do any checks in here for advantage or disadvantage
  //TO-DO: Implement full list: https://gist.github.com/OpenNingia/025ffcf269126a97503b34e243feee73
  //Currently just doing melee/ranged specific and condition based advantage/disadvantage
  let advantage = false;
  let disadvantage = false;

  //--- Ranged specific checks ---
  if (resolveAttackStore.weapon.Type.includes(WeaponTypes.Ranged)) {
    let enemies = combatStore.enemies;
    enemies.forEach((enemy) => {
      //Ranged Attack with a weapon/spell, or other means, you have disadvantage if you are within 5 feet
      //of a Hostile creature who can see you and who isn't Incapacitated
      //TO-DO: Come back to this when we decided how to handle hidden and add that check
      if (
        calculateDistance(enemy.location, resolveAttackStore.player.location) <=
          5 &&
        !enemy.conditions.includes(Conditions.Incapacitated) &&
        (!enemy.conditions.includes(Conditions.Blinded) ||
          enemy.senses.includes(Senses.Blindsight))
      ) {
        disadvantage = true;
      }
    });

    if (combatStore.distanceToTarget > resolveAttackStore.weapon.BestRange) {
      disadvantage = true;
    }
    //Manually check prone as special case
    if (resolveAttackStore.enemyTarget.conditions.includes(Conditions.Prone)) {
      disadvantage = true;
    }
  }

  //--- Melee specific checks ---
  else if (resolveAttackStore.weapon.Type === WeaponTypes.Melee) {
    if (
      combatStore.distanceToTarget <= 5 &&
      resolveAttackStore.enemyTarget.conditions.includes(Conditions.Prone)
    ) {
    }
  }

  //--- Conditions on target ---
  resolveAttackStore.enemyTarget.conditions.forEach((condition) => {
    //Parse mobX observable to JS
    condition = toJS(condition);
    if (condition.condition.AttacksIn.includes(RollType.Advantage.toString())) {
      advantage = true;
    }
    //I.e invisible condition (we also consider dodge a condition)
    if (
      condition.condition.AttacksIn.includes(RollType.Disadvantage.toString())
    ) {
      disadvantage = true;
    }
  });

  //--- Conditions on attacker ---
  resolveAttackStore.player.conditions.forEach((condition) => {
    if (
      condition.condition.AttacksOut.includes(RollType.Advantage.toString())
    ) {
      advantage = true;
    }
    if (
      condition.condition.AttacksOut.includes(RollType.Disadvantage.toString())
    ) {
      disadvantage = true;
    }
  });

  //Then set the roll type after any checks
  if (advantage && disadvantage) {
    setRollType(RollType.Normal);
  } else if (advantage) {
    setRollType(RollType.Advantage);
    outputToChat("You have advantage on the roll - Rolling with advantage");
  } else if (disadvantage) {
    setRollType(RollType.Disadvantage);
    outputToChat(
      "You have disadvantage on the roll - Rolling with disadvantage"
    );
  } else {
    //This may not be needed but nice to have it here just incase.
    setRollType(RollType.Normal);
  }
});

export const setPlayer = action((player) => {
  resolveAttackStore.player = player;
});
export const setMod = action((mod) => {
  resolveAttackStore.mod = mod;
});
export const setWeapon = action((weapon) => {
  resolveAttackStore.weapon = weapon;
});
export const setAttacker = action((attacker) => {
  resolveAttackStore.attacker = attacker;
});
export const setTotal = action((total) => {
  resolveAttackStore.total = total;
});
export const setResolveAttackWindow = action((window) => {
  resolveAttackStore.resolveAttackWindow = window;
});
export const setAttackResult = action((result) => {
  resolveAttackStore.attackResult = result;
});
export const setDamageResult = action((result) => {
  resolveAttackStore.damageResult = result;
});
export const setCrit = action((bool) => {
  resolveAttackStore.crit = bool;
});
export const setAttackDeclared = action((bool) => {
  resolveAttackStore.attackDeclared = bool;
});
export const setAttackHit = action((bool) => {
  resolveAttackStore.attackHit = bool;
});
export const setCanClose = action((bool) => {
  resolveAttackStore.canClose = bool;
});
export const setDamageDealt = action((bool) => {
  resolveAttackStore.damageDealt = bool;
});
export const setRollType = action((rollType) => {
  resolveAttackStore.rollType = rollType;
});

export default resolveAttackStore;
