import { Dice } from "./dice";
import { Socials } from "./socials";
export function SideBar() {
  return (
    <>
      <div id="side-bar">
        <div id="instructions">
          <img
            id="banner-logo"
            src="./logo.png"
            alt="DungeonFysh banner logo"
          />
          <h2 id="instructions-header">Read this first!</h2>
          <hr />
          <button id="signup-button">
            Click here to register for updates!
          </button>
          <Socials />
          <hr style={{ margin: 0, padding: 0 }} />
          <p>
            Hi there and welcome to DungeonFysh, a little prototype Dungeon
            Master (DM) for Dungeons and Dragons 5e.{" "}
          </p>
          <p>
            Fancy a go at D&D but don’t have a friendly DM nearby to take you on
            an adventure? No fear, this little friendly D&D fysh is here!{" "}
          </p>
          <p>
            In this prototype DungeonFysh is going to take you on a little
            adventure in a fantasy realm and you get to play a level 3 Human
            Champion Fighter! Very exciting! Feel free to use this{" "}
            <a href="https://www.dndbeyond.com/characters/92526621/jrICUu">
              DNDBeyond character sheet
            </a>{" "}
            for your character as reference.{" "}
          </p>
          <p>
            In order to go on the adventure you just need to type in your
            responses to DungeonFysh’s story – absolutely zero special knowledge
            required. Combat is handled by an 'in-app' system. It should feel
            intuitive, but check below for specific rules, and you can always
            ask DungeonFysh!{" "}
          </p>
          <p>
            In General Have fun and be descriptive of what you get up to in the
            fantasy world. Ask DungeonFysh for descriptions of what you see
            around you and have fun role-playing, act out what you do and
            describe it! Want to train a wild rabbit to be your travelling
            companion? Go for it! Want to set the scene of your burnished beard
            glowing in the firelight? Go wild and describe your gorgeous flowing
            locks. DungeonFysh will love it too and build on whatever story you
            want to tell.{" "}
          </p>
          <p>
            It’s worth bearing in mind that DungeonFysh is just a little minnow
            at this point and still learning the ropes of DMing so please go
            easy on them.{" "}
          </p>
          <ul>
            <li>
              DungeonFysh is in a prototype stage and is taking its very first
              swim into the big sea of D&D. They’ll learn over time, so be
              patient with him.
            </li>
            <li>
              You can ask for rules or guidance. He may get rules wrong, and you
              can gently correct!
            </li>
            <li>It’s currently only single player.</li>
            <li>There’s no save feature just yet.</li>
            <li>He has a somewhat limited memory of chat.</li>
            <li>
              He DMs in good faith. This little fysh is easy to confuse or
              manipulate in your favour as your inputs aren’t screened for
              cheating the system. For the best experience be honest and go with
              the dice – trust us, it’s way more fun!
            </li>
            <li>
              Please keep it PG, as we’ve said, DungeonFysh is just a little
              fysh.
            </li>
          </ul>
          <p>
            That being said, we’re very excited for all the things to come from
            DungeonFysh in the future and we hope, after going on one of their
            little adventures, you will be too.{" "}
          </p>
          <p>
            At the moment DungeonFysh is small fry, but we’re hoping you love
            them as much as we do and want to see them grow into a full-fledged
            AI DM. We’ve got big aspirations so please register for updates,
            like new developments and future Kick-starters!{" "}
          </p>
          <ul>
            <li>Multiple players so you don’t have to D&D alone</li>
            <li>
              NPC party members. Find someone fun in your adventure you think
              will be helpful along the way? Ask them to buddy up with you!
            </li>
            <li>Accurate rulings and better combat encounters</li>
            <li>Character generation</li>
            <li>Preset campaigns</li>
            <li>Voice Input and Output</li>
            <li>And much, much more!</li>
          </ul>
          <p>
            All constructive feedback is welcome. After each response from
            DungeonFysh is a feedback button. Click that to raise any issue with
            that given response and all the necessary information will be sent
            through.
          </p>

          <hr></hr>
          <h2 id="combat-rules-header">Combat</h2>
          <p>
            Combat is handled by a somewhat automated system. DungeonFysh should
            detect combat starting. He will check with you and ask you to
            confirm by clicking a start-combat button. When you click this,
            combat will begin.{" "}
          </p>
          <p>
            At this point a grid will appear and you can click on the grid to
            move when it is your turn (you can see the current turn in the
            turn-order display). If you want to take actions you can click on
            yourself, or any enemy, and relevant available actions will be
            shown. For example, your melee weapons will only appear when you are
            within range of the enemy you clicked on.{" "}
          </p>
          <p>
            Keep an eye on the chat, as the enemy's actions will be written
            there. If combat is too tough, you can always flee using the flee
            button!{" "}
          </p>
          <p>
            Other than this, there is not much to it. We are always adding new
            features and expanding the combat system. You can ask DungeonFysh
            for any clarifications, although he does not have any direct control
            over combat.{" "}
          </p>
          <p>With all that in mind, have fun on your adventures, traveler!</p>

          <div id="logo-container">
            <img id="df-logo" src="./logo512.png" alt="DungeonFysh logo" />
          </div>
          <hr style={{ marginBottom: 0, paddingBottom: 0 }} />
          <Socials />
          <hr style={{ margin: 0, padding: 0 }} />
        </div>
        <Dice />
      </div>
    </>
  );
}
