import resolveAttackStore, {
  attackButton,
  damageButton,
} from "./resolve-attack-window-store";
import { observer } from "mobx-react-lite";

const ResolveAttackWindow = observer((props) => {
  return (
    <div className="resolve-attack-window">
      <div id="attack-div">
        <button id="attackRollButton" onClick={attackButton}>
          Roll your attack!
        </button>
        <p id="attack-result">
          Attack result: {resolveAttackStore.attackResult}
        </p>
      </div>
      <div id="damage-div">
        <button id="damageRollButton" onClick={damageButton}>
          {handleDamage()}
        </button>
        <p id="damage-result">
          Damage dealt:
          {" " + resolveAttackStore.damageResult > 0
            ? resolveAttackStore.damageResult
            : ""}
        </p>
      </div>
      <button onClick={() => handleClose(props)}>Close</button>
    </div>
  );
});

const handleDamage = () => {
  if (resolveAttackStore.crit) {
    return "CRIT! Roll for damage";
  }
  return resolveAttackStore.attackHit ? "Hit! Roll for damage" : "";
};

const handleClose = (props) => {
  if (!resolveAttackStore.canClose) {
    alert(
      "You must resolve the attack before you can close the attack window" +
        "\n\n" +
        "Roll for damage!"
    );
    return;
  }
  props.setResolveAttackVisible(false);
};

export default ResolveAttackWindow;
