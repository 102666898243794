import {
  d10Roll,
  d12Roll,
  d20Roll,
  d4Roll,
  d6Roll,
  d8Roll,
} from "../data/Rolls";

export function Dice() {
  function handleClick(rollFunc, name) {
    document.getElementById("output-window").innerHTML =
      name + ": " + rollFunc();
  }

  return (
    <div id="square-section">
      <div id="dice-roller-header">Dice Roller</div>
      <table>
        <tbody>
          <tr>
            <td className="center-cell">
              <button
                className="dice-button"
                id="d20-button"
                onClick={() => handleClick(d20Roll, "D20")}
              >
                D20
              </button>
            </td>
            <td className="center-cell">
              <button
                className="dice-button"
                id="d12-button"
                onClick={() => handleClick(d12Roll, "D12")}
              >
                D12
              </button>
            </td>
            <td className="center-cell">
              <button
                className="dice-button"
                id="d10-button"
                onClick={() => handleClick(d10Roll, "D10")}
              >
                D10
              </button>
            </td>
          </tr>
          <tr>
            <td className="center-cell">
              <button
                className="dice-button"
                id="d8-button"
                onClick={() => handleClick(d8Roll, "D8")}
              >
                D8
              </button>
            </td>
            <td className="center-cell">
              <button
                className="dice-button"
                id="d6-button"
                onClick={() => handleClick(d6Roll, "D6")}
              >
                D6
              </button>
            </td>
            <td className="center-cell">
              <button
                className="dice-button"
                id="d4-button"
                onClick={() => handleClick(d4Roll, "D4")}
              >
                D4
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div id="output-window"></div>
    </div>
  );
}
