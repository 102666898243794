import { RollType, ProficencyType, Conditions } from "./Rules";

//Roll for initiative
export function initiativeRoll(actor) {
  return nonProficientRoll(actor.DexMod) + actor.DexMod / 100;
}

//Non-proficient  roll D20
//TO-DO: Add nat20 check (see ProficientRoll for example)
export function nonProficientRoll(modifier, rollType = RollType.Normal) {
  return d20Roll(rollType) + modifier;
}

//Proficient roll D20
export function proficientRoll(
  modifier,
  proficencyBonus = 0,
  rollType = RollType.Normal
) {
  let roll = d20Roll(rollType);
  return [roll + modifier + proficencyBonus, roll];
}

//TO-DO: consider advantage on these rolls
export function grapple(contester, contestee) {
  //First check size limitations
  if (contestee.size > contester.size + 1) {
    return "The target is too big for you to grapple";
  }
  let contesterRoll = contester.skillCheck("athletics");
  let contesteeRoll =
    contestee.skills.athletics.mod > contestee.skills.acrobatics.mod
      ? contestee.skillCheck("athletics")
      : contestee.skillCheck("acrobatics");
  if (contesteeRoll >= contesterRoll) {
    return "The grapple attempt failed! Your target broke free.";
  }
  //Otherwise grapple succeeds
  contestee.addCondition(Conditions.Grappled);
  return "Grapple succesful! Your target is now grappled by you.";
}
export function skillCheckRoll(modifier, proficiency, proficencyBonus) {
  if (proficiency === ProficencyType.NonProficient) {
    return nonProficientRoll(modifier);
  }
  if (proficiency === ProficencyType.Proficient) {
    return proficientRoll(modifier, proficencyBonus);
  }
  //else expertise
  return proficientRoll(modifier, proficencyBonus * 2);
}

export function d20Roll(rollType = RollType.Normal) {
  let roll = [
    Math.floor(Math.random() * 20) + 1,
    Math.floor(Math.random() * 20) + 1,
  ];
  return rollType === RollType.Advantage
    ? Math.max(roll[0], roll[1])
    : rollType === RollType.Disadvantage
    ? Math.min(roll[0], roll[1])
    : roll[0];
}
export function d12Roll() {
  return Math.floor(Math.random() * 12) + 1;
}
export function d10Roll() {
  return Math.floor(Math.random() * 10) + 1;
}
export function d8Roll() {
  return Math.floor(Math.random() * 8) + 1;
}
export function d6Roll() {
  return Math.floor(Math.random() * 6) + 1;
}
export function d4Roll() {
  return Math.floor(Math.random() * 4) + 1;
}

//Bit of a special case that saves code if posted here
export function unarmedStrike(actor) {
  let strMod = actor.StrMod;
  return strMod >= 0 ? strMod + 1 : 0;
}
