import "./combatStyle.css";
import React, { useRef } from "react";
import { observer } from "mobx-react";
import { GridTypes } from "../../data/GridTypes";

const GridSquare = observer(
  ({ gridScale, value, onSquareClick, squareRef }) => {
    let content;
    squareRef = useRef(null);
    const thisStyle = {
      width: gridScale,
      height: gridScale,
    };

    if (value.type === GridTypes.EmptyType) {
      content = "";
    } else if (value.type === GridTypes.PlayerType) {
      content = (
        <span id="player-grid" className="player-symbol">
          {"\u{2694}"}
        </span>
      );
    } else if (value.type === GridTypes.EnemyType) {
      content = (
        <span id="enemy-grid" className="enemy-symbol">
          {"\u{2694}"}
        </span>
      );
    } else {
      content = "U";
    }

    return (
      <div
        style={thisStyle}
        className="grid-square"
        onClick={() => onSquareClick(squareRef.current)}
        ref={squareRef}
      >
        {content}
      </div>
    );
  }
);
export default GridSquare;
