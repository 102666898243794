import { useEffect } from "react";
import { FeedBackModalinIndex } from "..";

// MODAL for prompt feedback
export function FeedbackModal() {
  
  useEffect(() => {
    // This function will be called when the component has loaded
    FeedBackModalinIndex();
  }, []);

  return (
    <div id="feedback-modal">
      <form id="feedback-form">
        <h2>Help us, help you.</h2>
        <div id="feedback-category-div">
          <label htmlFor="feedback-category">Category:</label>
          <select id="feedback-category">
            <option value="Default">Default</option>
            <option value="Combat">Combat</option>
            <option value="RolePlaying">RolePlaying</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <label htmlFor="feedback-text">Feedback:</label>
        <textarea
          id="feedback-text"
          placeholder="Please explain your feedback..."
        ></textarea>
        <label htmlFor="desired-completion">Desired Response:</label>
        <textarea
          id="desired-completion"
          placeholder="Please enter your desired response example..."
        ></textarea>
        <div>
          <button id="submit-feedback-button" type="submit">
            Submit
          </button>
          <button id="close-feedback-button">Close</button>
        </div>
      </form>
    </div>
  );
}
