import React from "react";
import Icosahedron from "./icosahedron";
import { Canvas } from "react-three-fiber";
import "./loading.css";

const Loading = () => {
  return (
    <div className="loading">
      <div className="loading-content">
        <Canvas style={{ position: "absolute", top: 0, left: 0 }}>
          <ambientLight intensity={0.5} />
          <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
          <Icosahedron />
        </Canvas>
        <div id="loading-info">Loading...</div>
      </div>
    </div>
  );
};

export default Loading;
