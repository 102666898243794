import React from "react";
import { observer } from "mobx-react-lite";
import combatStore from "./combat-store";

const TurnOrder = observer(() => {
  return (
    <div id="turn-order" className="turn-order-container">
      <div id="turn-order-header" className="turn-order-header">
        Turn Order
      </div>
      <ul>
        {combatStore.turnOrder.map((turnOrderObject, index) => (
          <li key={index} className="turn-order-item">
            {turnOrderObject.actor.name}: {turnOrderObject.initiative}
          </li>
        ))}
      </ul>
    </div>
  );
});

export default TurnOrder;
