import "./indexStyle.css";
import React from "react";
import { SideBar } from "./components/side-bar";
import { ChatWindow } from "./components/chatWindow";
import { Overlay } from "./components/overlay";
import { FeedbackModal } from "./components/feedback-modal";
import { CombatWindow } from "./components/combat/combat-window";
import combatStore from "./components/combat/combat-store";
import { observer } from "mobx-react";
import Loading from "./components/loading";

const App = observer(() => {
  return (
    <>
      <Overlay />
      <FeedbackModal />
      {combatStore.loading ? <Loading /> : <></>}
      <div id="main-container">
        <SideBar />
        {combatStore.showCombatWindow ? <CombatWindow /> : <></>}
        <ChatWindow />
      </div>
    </>
  );
});

export default App;
