import Combat from "./combat";
import { gridSize } from "./combat-store";
import ActorClickedMenu from "./actor-clicked-menu";
import React, { useState } from "react";
import ResolveAttackWindow from "./resolve-attack-window";
import { setResolveAttackWindow } from "./resolve-attack-window-store";
import { ChatWindow } from "../chatWindow";

export function CombatWindow() {
  const [isResolveAttackVisible, setResolveAttackVisible] = useState(false);
  const [isActorClickedMenuVisible, setActorClickedMenuVisible] =
    useState(false);
  const [position, setPosition] = useState({});

  const updateActorClickedMenuPosition = (newPosition) => {
    setPosition(newPosition);
  };
  const resolveAttackWindow = (
    <ResolveAttackWindow
      setResolveAttackVisible={setResolveAttackVisible}
      isResolveAttackVisible={isResolveAttackVisible}
    />
  );

  setResolveAttackWindow(resolveAttackWindow);
  return (
    <div id="combat-window">
      <Combat
        gridSize={gridSize}
        setActorClickedMenuVisible={setActorClickedMenuVisible}
        isActorClickedMenuVisible={isActorClickedMenuVisible}
        updateActorClickedMenuPosition={updateActorClickedMenuPosition}
      />
      {isResolveAttackVisible && resolveAttackWindow}
      {isActorClickedMenuVisible && <ActorClickedMenu position={position} />}
    </div>
  );
}
