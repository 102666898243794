import { useEffect } from "react";
import { SignUpOverlayIndex } from "..";

// Overlay(MODAL) for email signup.
export function Overlay() {
  useEffect(() => {
    // This function will be called when the component has loaded
    SignUpOverlayIndex();
  }, []);

  return (
    <div id="overlay">
      <div id="overlay-container">
        <form id="signup-form">
          <label>Name:</label>
          <input id="signupName" type="text" name="name" />
          <br />
          <label>Email address:</label>
          <input id="signupEmail" type="email" name="email" />
          <br />
          <button id="submitEmailButton" type="submit">
            Sign Up
          </button>
        </form>
        <button id="close-button">Close</button>
      </div>
    </div>
  );
}
