import GridSquare from "./grid-square";
import "./combatStyle.css";
import { observer } from "mobx-react";
import combatStore from "./combat-store";

const CombatGrid = observer(({ gridSize }) => {
  const combatGrid = [];
  const gridScale = "29px";
  const gridStyle = {
    gridTemplateColumns: `repeat(${gridSize}, ${gridScale})`,
  };
  for (let row = 0; row < gridSize; row++) {
    combatGrid.push([]);
    for (let col = 0; col < gridSize; col++) {
      const value = combatStore.combatGrid[col][row];
      combatGrid[row].push(
        <GridSquare
          key={`${col}${row}`}
          store={combatStore}
          value={value}
          gridScale={gridScale}
          onSquareClick={(squareRef) => handleSquareClick(col, row, squareRef)}
        />
      );
    }
  }

  function handleSquareClick(x, y, squareRef) {
    combatStore.combat.gridSquareClicked([x, y], squareRef);
  }

  return (
    <div className="combat-grid" style={gridStyle}>
      {combatGrid}
    </div>
  );
});

export default CombatGrid;
