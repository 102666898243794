import { makeAutoObservable } from "mobx";
import { GridTypes } from "./GridTypes";

//This class is just to make it smoother when checking the type of a gridsquare
class EmptyGrid {
  type;

  constructor() {
    makeAutoObservable(this);
    this.type = GridTypes.EmptyType;
  }
}

export default EmptyGrid;
