import { action, observable } from "mobx";

export const playerStore = observable({
  players: [],

  addPlayer(Player) {
    this.players.push(Player);
  },

  updatePlayerLocation: action(function (player, x, y) {
    player.setLocation(x, y);
  }),
});
