import { observable, makeObservable, computed, action } from "mobx";
import { playerStore } from "./player-store";
import { GridTypes } from "./GridTypes";
import combatStore from "../components/combat/combat-store";
import {
  Weapons,
  CharacterClasses,
  ProficencyBonusByLevel,
  ProficencyType,
  CreatureSize,
  Conditions,
} from "./Rules";
import {
  calculateAbilityScoreModifier,
  calculateAverageMaxHP,
  outputToChat,
  removeItemOnce,
} from "./GlobalFunctions";
import { grapple, initiativeRoll, skillCheckRoll } from "./Rolls";
import resolveAttackStore, {
  resolveAttack,
} from "../components/combat/resolve-attack-window-store";

class Player {
  type;
  characterClass;
  level;
  name;
  HP;
  AC;
  critRange;
  location;
  abilityScores;
  speed;
  remainingMovement;
  remainingActions;
  remainingAttacks;
  remainingBonusActions;
  remainingReactions;
  equipped;
  conditions;
  senses;
  skills;
  size;
  attacks;

  constructor(name) {
    this.type = GridTypes.PlayerType;
    this.name = name;
    this.characterClass = CharacterClasses.Fighter;
    this.size = CreatureSize.Medium; //TO-DO calculate or pass value (based on race?)
    this.level = 3; //auto set for now
    //Player location is bottom right by deafult
    this.location = [combatStore.gridSize - 1, combatStore.gridSize - 1];
    this.critRange = 19; //Set for now (pass in creation and level up eventually)
    this.conditions = [];
    this.senses = [];
    //Player Stats

    this.attacks = 1; //TO-DO: Change when implementing levels.
    this.abilityScores = {
      Strength: 16,
      Dexterity: 11,
      Constitution: 15,
      Intelligence: 9,
      Wisdom: 13,
      Charisma: 14,
    };

    this.HP = this.MaxHP; //In constructor just set HP === MaxHP
    this.AC = 19; //19 for champ //Need to make computed and factor in equiped items/stats etc.

    this.skills = {
      //Many options for this but seems lest messy to store info in player like this
      athletics: {
        proficency: ProficencyType.NonProficient,
        mod: this.StrMod,
      },
      acrobatics: {
        proficency: ProficencyType.NonProficient,
        mod: this.StrMod,
      },
      sleightOfHand: {
        proficency: ProficencyType.NonProficient,
        mod: this.DexMod,
      },
      stealth: { proficency: ProficencyType.NonProficient, mod: this.DexMod },
      arcana: { proficency: ProficencyType.NonProficient, mod: this.IntMod },
      history: { proficency: ProficencyType.NonProficient, mod: this.IntMod },
      investigation: {
        proficency: ProficencyType.NonProficient,
        mod: this.IntMod,
      },
      nature: { proficency: ProficencyType.NonProficient, mod: this.IntMod },
      religion: { proficency: ProficencyType.NonProficient, mod: this.IntMod },
      animalHandling: {
        proficency: ProficencyType.NonProficient,
        mod: this.WisMod,
      },
      insight: { proficency: ProficencyType.NonProficient, mod: this.WisMod },
      medicine: { proficency: ProficencyType.NonProficient, mod: this.WisMod },
      perception: {
        proficency: ProficencyType.NonProficient,
        mod: this.WisMod,
      },
      survival: { proficency: ProficencyType.NonProficient, mod: this.WisMod },
      deception: {
        proficency: ProficencyType.NonProficient,
        mod: this.ChaMod,
      },
      intimidation: {
        proficency: ProficencyType.NonProficient,
        mod: this.ChaMod,
      },
      performance: {
        proficency: ProficencyType.NonProficient,
        mod: this.ChaMod,
      },
      persuasion: {
        proficency: ProficencyType.NonProficient,
        mod: this.ChaMod,
      },
    };
    this.equipped = { weapons: [Weapons.Longsword, Weapons.LightCrossbow] };
    this.speed = 30;
    //Set remainingMovement as speed in the constructor
    this.remainingMovement = this.speed;
    //Set all turn based
    this.remainingActions = 1;
    this.remainingBonusActions = 1;
    this.remaningReactions = 1;
    this.remainingAttacks = 1;

    //MobX settings
    makeObservable(this, {
      name: observable,
      MaxHP: computed,
      HP: observable,
      abilityScores: observable,
      StrMod: computed,
      DexMod: computed,
      ConMod: computed,
      IntMod: computed,
      WisMod: computed,
      ChaMod: computed,
      proficiencyBonus: computed,
      skills: observable,
      level: observable,
      location: observable,
      speed: observable,
      remainingMovement: observable,
      conditions: observable,
      remainingActions: observable,
      remainingBonusActions: observable,
      remaningReactions: observable,
      remainingAttacks: observable,
    });

    //New players automatically added to player store
    playerStore.addPlayer(this);
  }

  getName() {
    return this.name;
  }
  get MaxHP() {
    return calculateAverageMaxHP(this.characterClass, this.ConMod, this.level);
  }
  get StrMod() {
    return calculateAbilityScoreModifier(this.abilityScores.Strength);
  }
  get DexMod() {
    return calculateAbilityScoreModifier(this.abilityScores.Dexterity);
  }
  get ConMod() {
    return calculateAbilityScoreModifier(this.abilityScores.Constitution);
  }
  get IntMod() {
    return calculateAbilityScoreModifier(this.abilityScores.Intelligence);
  }
  get WisMod() {
    return calculateAbilityScoreModifier(this.abilityScores.Wisdom);
  }
  get ChaMod() {
    return calculateAbilityScoreModifier(this.abilityScores.Charisma);
  }
  getAllActionsOther() {
    let content = false;
    let Actions = [];
    if (this.getAttacks().length > 0 && this.remainingAttacks > 0) {
      content = true;
      Actions.push({ name: "Weapons", subItems: this.getAttacks() });
    }
    if (this.getStandardActionsOther().length > 0) {
      content = true;
      Actions.push({
        name: "StandardActions",
        subItems: this.getStandardActionsOther(),
      });
    }
    return content ? Actions : [{ name: "No suitable actions", subItems: "" }];
  }
  getAllActionsSelf() {
    let content = false;
    let Actions = [];
    if (this.getAttacks().length > 0 && this.remainingActions > 0) {
      content = true;
      Actions.push({
        name: "StandardActions",
        subItems: this.getStandardActionsSelf(),
      });
    }
    return content ? Actions : [{ name: "No suitable actions", subItems: "" }];
  }

  get proficiencyBonus() {
    return ProficencyBonusByLevel[this.level];
  }
  getAttacks() {
    let attacks = [];
    //Always add UnarmedStrike if in range (for now) and pass special damage calcuation
    if (combatStore.distanceToTarget <= Weapons.Unarmed.MaxRange) {
      attacks.push({
        name: "UnarmedStrike",
        action: () => resolveAttack(this, Weapons.Unarmed),
      });
    }
    //Go through equiped weapons and get out attacks
    this.equipped.weapons.forEach((weapon) => {
      //Additional checks here:
      //2h wpns with shields (action to don/dof shield)
      //This should include versatile checks (so check if shield on and then show 1h vs 2hands)
      //Check if weapon.type.includes(Type.Versatile)
      //Could do shield check then if its on only check 1, no point checking 2h wpns if shield on.
      if (combatStore.distanceToTarget <= weapon.MaxRange) {
        // Format: { name: 'someName', action: () => someAction }
        attacks.push({
          //Check if more than one attack and add both as additional drop down
          name: weapon.Name,
          action: () => resolveAttack(this, weapon),
        });
      }
    });
    return attacks;
  }
  //Return standard actions applicable to others, if we have action remaning.
  getStandardActionsOther() {
    if (this.remainingActions < 1) {
      return [];
    }
    let standardActionsSubItems = [];
    Object.values(this.standardActionsOther).forEach((standardAction) => {
      if (combatStore.distanceToTarget > 5 || this.remainingAttacks < 1) {
        //Skip things that we need to be in melee for and use attacks if we are too far or have no attacks
        if (
          standardAction.name === "Grapple" ||
          standardAction.name === "Shove"
        ) {
          return;
        }
      }
      standardActionsSubItems.push({
        name: standardAction.name,
        action: standardAction.action,
      });
    });
    return standardActionsSubItems;
  }
  //Return standard actions applicable to self, if we have action remaning.
  getStandardActionsSelf() {
    if (this.remainingActions < 1) {
      return [];
    }
    let standardActionsSubItems = [];
    Object.values(this.standardActionsSelf).forEach((standardAction) => {
      standardActionsSubItems.push({
        name: standardAction.name,
        action: standardAction.action,
      });
    });
    return standardActionsSubItems;
  }
  getSpells() {
    //TO-DO: implement spells
  }
  skillCheck(skillName) {
    let skill = this.skills[skillName];
    return skillCheckRoll(skill.mod, skill.proficency, this.proficiencyBonus);
  }
  getLocation() {
    return this.location;
  }
  useMovement = action((move) => {
    this.remainingMovement -= move;
  });
  addMovement = action((amount) => {
    this.remainingMovement += amount;
  });
  addCondition = action((condition, duration = null) => {
    this.conditions.push({ condition: condition, duration: duration });
    outputToChat(condition.Name + " has been applied to " + this.name);
  });
  removeCondition = action((condition) => {
    removeItemOnce(this.conditions, condition);
  });
  setLocation = action((x, y) => {
    this.location = [x, y];
  });
  //use 1 attack, and run passed function if it is passed
  useAttack = action((func = null) => {
    this.remainingAttacks--;
    if (func) {
      return func();
    }
  });
  //use 1 action, and run passed function if it is passed
  useAction = action((func = null) => {
    this.remainingActions--;
    if (func) {
      return func();
    }
  });
  //use 1 bonus action, and run passed function if it is passed
  useBonusAction = action((func = null) => {
    this.remainingBonusActions--;
    if (func) {
      return func();
    }
  });
  //use 1 reaction, and run passed function if it is passed
  useReaction = action((func = null) => {
    this.remainingReactions--;
    if (func) {
      return func();
    }
  });
  reduceHP = action((amount) => {
    this.HP -= amount;
  });
  rollInitiative() {
    return initiativeRoll(this);
  }
  //Process things to do with an end of turn. e.g reset currentMovement.
  //This is called from combat.js
  endOfTurn = action(() => {
    this.remainingMovement = this.speed;
    this.remainingActions = 1;
    this.remainingBonusActions = 1;
    this.remaningReactions = 1;
    this.remainingAttacks = this.attacks;
  });

  //Defined here as can't figure out a better way to do it.
  standardActionsOther = {
    //Attack: "Attack" //Handled above
    //Spell: "Cast a Spell" // Need to implement spells
    Grapple: {
      name: "Grapple",
      action: () =>
        //TO-DO: think about multiple attacks
        this.useAction(
          this.useAttack(
            outputToChat(grapple(this, resolveAttackStore.enemyTarget))
          )
        ),
    },
    //Help: "Help",
    //Shove: { name: "Shove", action: () => out },
    //Use an Object",
  };
  //Defined here as can't figure out a better way to do it.
  standardActionsSelf = {
    Dash: {
      name: "Dash",
      action: () => this.useAction(this.addMovement(this.speed)),
    },
    Dodge: {
      name: "Dodge",
      action: () => this.useAction(this.addCondition(Conditions.Dodge)),
    },
    //Hide: "Hide",
    //Ready: "Ready",
    //Search: "Search",
    //Use an Object",
  };
}

export default Player;
