import { d8Roll, unarmedStrike } from "./Rolls";

//https://gist.github.com/OpenNingia/025ffcf269126a97503b34e243feee73
//^ Useful link for conditions and advantage/disadvantage

export const CharacterClasses = {
  Artificer: "Artificer",
  Barbarian: "Barbarian",
  Bard: "Bard",
  Cleric: "Cleric",
  Druid: "Druid",
  Fighter: "Fighter",
  Monk: "Monk",
  Paladin: "Paladin",
  Ranger: "Ranger",
  Rogue: "Rogue",
  Sorcerer: "Sorcerer",
  Warlock: "Warlock",
  Wizard: "Wizard",
};
export const ClassHitDie = {
  Artificer: 8,
  Barbarian: 12,
  Bard: 8,
  Cleric: 8,
  Druid: 8,
  Fighter: 10,
  Monk: 8,
  Paladin: 10,
  Ranger: 10,
  Rogue: 8,
  Sorcerer: 6,
  Warlock: 8,
  Wizard: 6,
};
export const ProficencyType = {
  Proficient: "Proficient",
  NonProficient: "NonProficient",
  Expertise: "Expertise",
};
export const ProficencyBonusByLevel = {
  1: 2,
  2: 2,
  3: 2,
  4: 2,
  5: 3,
  6: 3,
  7: 3,
  8: 3,
  9: 4,
  10: 4,
  11: 4,
  12: 4,
  13: 5,
  14: 5,
  15: 5,
  16: 5,
  17: 6,
  18: 6,
  19: 6,
  20: 6,
};
export const CreatureSize = {
  Tiny: 1,
  Small: 2,
  Medium: 3,
  Large: 4,
  Huge: 5,
  Gargantuan: 6,
};
export const RollType = {
  Advantage: "Advantage",
  Disadvantage: "Disadvantage",
  Normal: "Normal",
  Incapacitated: "Incapacitated",
};
//Commented out actions are not implemented yet

//TO-DO: Many of these need implementing fully - look at any 'text'.
//Current only contains attack data.
export const Conditions = {
  Blinded: {
    Name: "Blinded",
    AttacksIn: [RollType.Advantage],
    AttacksOut: [RollType.Disadvantage],
  },
  Charmed: {
    Name: "Charmed",
    AttacksIn: [RollType.Normal],
    AttacksOut: ["Can't attack charmer"],
  },
  Dodge: {
    Name: "Dodge",
    AttacksIn: [RollType.Disadvantage],
    AttacksOut: [RollType.Normal],
  },
  Deafened: {
    Name: "Deafened",
    AttacksIn: [RollType.Normal],
    AttacksOut: [RollType.Normal],
  },
  Exhaustion1: {
    Name: "Exhaustion1",
    AttacksIn: [RollType.Normal],
    AttacksOut: [RollType.Normal],
  },
  Exhaustion2: {
    Name: "Exhaustion2",
    AttacksIn: [RollType.Normal],
    AttacksOut: [RollType.Normal],
  },
  Exhaustion3: {
    Name: "Exhaustion3",
    AttacksIn: [RollType.Normal],
    AttacksOut: [RollType.Disadvantage],
  },
  Exhaustion4: {
    Name: "Exhaustion4",
    AttacksIn: [RollType.Normal],
    AttacksOut: [RollType.Disadvantage],
  },
  Exhaustion5: {
    Name: "Exhaustion5",
    AttacksIn: [RollType.Normal],
    AttacksOut: [RollType.Disadvantage],
  },
  Exhaustion6: {
    Name: "Exhaustion6",
    AttacksIn: [RollType.Normal],
    AttacksOut: [RollType.Incapacitated],
  },
  Frightened: {
    Name: "Frightened",
    AttacksIn: [RollType.Normal],
    AttacksOut: [RollType.Disadvantage],
  },
  Grappled: {
    Name: "Grappled",
    AttacksIn: [RollType.Normal],
    AttacksOut: [RollType.Normal],
  },
  Incapacitated: {
    Name: "Incapacitated",
    AttacksIn: [RollType.Advantage],
    AttacksOut: [RollType.Disadvantage],
  },
  Invisible: {
    Name: "Invisible",
    AttacksIn: [RollType.Disadvantage],
    AttacksOut: [RollType.Advantage],
  },
  Paralyzed: {
    Name: "Paralyzed",
    AttacksIn: [RollType.Advantage, "auto crit"],
    AttacksOut: [RollType.Incapacitated],
  },
  Petrified: {
    Name: "Petrified",
    AttacksIn: [RollType.Advantage],
    AttacksOut: [RollType.Incapacitated],
  },
  Poisoned: {
    Name: "Poisoned",
    AttacksIn: [RollType.Normal],
    AttacksOut: [RollType.Disadvantage],
  },
  Prone: {
    Name: "Prone",
    AttacksIn: ["Set-manually in handleAdvantage()"],
    AttacksOut: [RollType.Disadvantage],
  },
  Restrained: {
    Name: "Restrained",
    AttacksIn: [RollType.Advantage],
    AttacksOut: [RollType.Disadvantage],
  },
  Stunned: {
    Name: "Stunned",
    AttacksIn: [RollType.Advantage],
    AttacksOut: [RollType.Incapacitated],
  },
  Unconscious: {
    Name: "Unconscious",
    AttacksIn: [RollType.Advantage, "auto crits"],
    AttacksOut: [RollType.Incapacitated],
  },
};

export const Senses = {
  Blindsight: "Blindsight",
  Truesight: "Truesight",
};

export const WeaponTypes = {
  Martial: "Martial",
  Simple: "Simple",
  Melee: "Melee",
  Ranged: "Ranged",
  NaturalWeapon: "NaturalWeapon",
  Finesse: "Finesse",
};
export const DamageTypes = {
  Slashing: "Slashing",
  Bludgeoning: "Bludgeoning",
  Piercing: "Piercing",
};
export const Weapons = {
  Longsword: {
    Name: "Longsword",
    Type: [WeaponTypes.Melee, WeaponTypes.Martial],
    DamageType: [DamageTypes.Slashing],
    MaxRange: 5,
    DamageDie: () => d8Roll(), //need to handle 2h somehow?
  },
  Unarmed: {
    Name: "UnarmedStrike",
    Type: [WeaponTypes.NaturalWeapon],
    DamageType: [DamageTypes.Bludgeoning],
    MaxRange: 5,
    DamageDie: (actor) => unarmedStrike(actor), //special case
  },
  LightCrossbow: {
    Name: "LightCrossbow",
    Type: [WeaponTypes.Ranged, WeaponTypes.Simple],
    DamangeType: [DamageTypes.Piercing],
    MaxRange: 320,
    BestRange: 80,
    DamageDie: () => d8Roll(),
  },
};
