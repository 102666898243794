import React, { Component } from "react";
import combatStore from "./combat-store";
import { computed, makeObservable } from "mobx";

class ActorClickedMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openItemIndex: -1,
    };

    makeObservable(this, {
      menuItems: computed,
    });
  }

  get menuItems() {
    return combatStore.actorDropDown;
  }

  handleClick = (item) => {
    if (item.action) {
      item.action();
    } else {
      this.setState({
        openItemIndex:
          item.index === this.state.openItemIndex ? -1 : item.index,
      });
    }
  };

  renderMenuItem = (item, index) => {
    const menuItemName = item.name;
    const subItems = item.subItems;
    return (
      <li
        className="li-menu-item"
        key={index}
        onClick={() => this.handleClick({ ...item, index })}
      >
        {menuItemName}
        {subItems &&
          subItems.length > 0 &&
          index === this.state.openItemIndex && (
            <ul>
              {subItems.map((subItem, subIndex) =>
                this.renderMenuItem(subItem, subIndex)
              )}
            </ul>
          )}
      </li>
    );
  };

  render() {
    return (
      <div
        id="enemy-clicked-menu"
        className="enemy-clicked-menu-container"
        style={{
          position: "absolute",
          left: this.props.position.left || "50%",
          top: this.props.position.top || "50%",
        }}
      >
        <ul>
          {this.menuItems.map((menuItem, index) =>
            this.renderMenuItem(menuItem, index)
          )}
        </ul>
      </div>
    );
  }
}

export default ActorClickedMenu;
