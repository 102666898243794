import { observer } from "mobx-react-lite";
import { GridTypes } from "../../data/GridTypes";
import combatStore from "./combat-store";

const CombatInfoWindow = observer(() => {
  if (combatStore.combatInfoReady) {
    return (
      <div className="combat-info-window">
        <table id="combat-info">
          <thead>
            <tr>
              <th>HP</th>
              <th>Movement</th>
              <th>Actions</th>
              <th>Attacks</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {combatStore.turnOrder[0].actor.type === GridTypes.PlayerType
                  ? combatStore.turnOrder[0].actor.HP +
                    "/" +
                    combatStore.turnOrder[0].actor.MaxHP
                  : ""}
              </td>
              <td>
                {combatStore.turnOrder[0].actor.type === GridTypes.PlayerType
                  ? combatStore.turnOrder[0].actor.remainingMovement
                  : ""}
              </td>
              <td>
                {combatStore.turnOrder[0].actor.type === GridTypes.PlayerType
                  ? combatStore.turnOrder[0].actor.remainingActions
                  : ""}
              </td>
              <td>
                {combatStore.turnOrder[0].actor.type === GridTypes.PlayerType
                  ? combatStore.turnOrder[0].actor.remainingAttacks
                  : ""}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  } else {
    return <></>;
  }
});

export default CombatInfoWindow;
