export function Socials() {
  return (
    <div id="socials-container">
      <div className="social">
        <a href="https://www.instagram.com/dungeonfysh/">
          <img
            src="./instalogo.png"
            alt="instagram-social"
            id="insta-social"
          ></img>
        </a>
      </div>
      <div className="social">
        <a href="https://twitter.com/DungeonFysh/">
          <img
            src="./twitterlogo.png"
            alt="twitter-social"
            id="twitter-social"
          ></img>
        </a>
      </div>
      <div className="social">
        <a href="https://www.reddit.com/r/DungeonFysh/">
          <img
            src="./redditlogo.png"
            alt="reddit-social"
            id="reddit-social"
          ></img>
        </a>
      </div>
    </div>
  );
}
