import { observable } from "mobx";
import { action } from "mobx";
import { GridTypes } from "../../data/GridTypes";
import EmptyGrid from "../../data/EmptyGrid";
import {
  outputToChat,
  removeItemFromArray,
  wait,
} from "../../data/GlobalFunctions";
import { playerStore } from "../../data/player-store";
import { tryGeneratePrompt, tryGetDmResponse } from "../..";

//Manages grid size and changes related variables (e.g player locations (bottom right placement))
//We will possibly want to pass this later on if we want bigger 'maps'
//Bare in mind this dictates the size of the grid at creation so think about order when setting gridsize
export const gridSize = 10;

const combatStore = observable({
  inCombat: false,
  showCombatWindow: false,
  combatInfoReady: false,
  loading: false,
  gridSize: gridSize,
  combat: null,
  combatGrid: Array(gridSize).fill(Array(gridSize).fill(new EmptyGrid())),
  actorDropDown: null,
  resolveAttackWindow: null,
  distanceToTarget: null,
  enemies: [], //Enemies are kept here (in combat-store) as they are only tracked in combat
  //Players are stored in player store ( as this can also be used for out of combat purposes)
  participants: [], //A combined list for enemies and players
  turnOrder: [], //A list of participants, with initiative as the key
});

export function getGridSquare(x, y) {
  return combatStore.combatGrid[x][y];
}

export const updateGridSquare = action((x, y, update) => {
  combatStore.combatGrid[x][y] = update;
});

export const setInCombat = action((bool) => {
  combatStore.inCombat = bool;
});
export const setLoading = action((bool) => {
  combatStore.loading = bool;
});
export const setCombatInfoReady = action((bool) => {
  combatStore.combatInfoReady = bool;
});

export const addTurn = action((turnObject) => {
  combatStore.turnOrder.push(turnObject);
});

//Adds an enemy to the enemies and participants arrays
export const addEnemies = action((enemies) => {
  enemies.forEach((enemy) => {
    combatStore.enemies.push(enemy);
    combatStore.participants.push(enemy);
  });
});

export const createTurnOrder = action(() => {
  //For now we auto roll initiative
});
export const clearTurnOrder = action(() => {
  combatStore.turnOrder = [];
});

export const sortTurnOrderDescending = action(() => {
  combatStore.turnOrder.sort((a, b) => b.initiative - a.initiative);
});

export const resetCombatGrid = action(() => {
  combatStore.combatGrid = Array(gridSize).fill(
    Array(gridSize).fill(new EmptyGrid())
  );
});

export const setCombatInstance = action((combatInstance) => {
  combatStore.combat = combatInstance;
});

export const startCombatButtonClicked = action(() => {
  setShowCombatWindow(true);
  //Combat is started in combat.js when the component mounts
});

export const setShowCombatWindow = action((bool) => {
  combatStore.showCombatWindow = bool;
});

//move on combatGrid if they have enough movement - use this for combat movements
export const moveActor = action((targetLocation, actor) => {
  let actorLocation = actor.location;
  let totalMovement = calculateDistance(actorLocation, targetLocation);
  if (
    totalMovement <= actor.remainingMovement &&
    validateMovement(targetLocation)
  ) {
    //Update location on instance of actor
    //This is not inside updateGridSquare as we may want to place objects etc and not always an
    //actor with location (worth checking later perhaps depending on how we handle objects on the grid)
    actor.setLocation(targetLocation[0], targetLocation[1]);
    actor.useMovement(totalMovement);
    //Update the grid to reflect this location change
    updateGridSquare(actorLocation[0], actorLocation[1], new EmptyGrid());
    updateGridSquare(targetLocation[0], targetLocation[1], actor);
  } else {
    return false;
  }
});

//Calculate distance in feet on a 5ft grid given starting and target locations
//Allows for diagonal movement/measurement
export const calculateDistance = (startingLocation, targetLocation) => {
  const [x1, y1] = startingLocation;
  const [x2, y2] = targetLocation;
  const xDiff = Math.abs(x1 - x2);
  const yDiff = Math.abs(y1 - y2);
  const distance = Math.max(xDiff, yDiff);
  return distance * 5; // convert to feet
};

export const validateMovement = (targetLocation) => {
  // Check if target location is within the bounds of the grid
  if (
    targetLocation[0] < 0 ||
    targetLocation[0] >= gridSize ||
    targetLocation[1] < 0 ||
    targetLocation[1] >= gridSize
  ) {
    return false;
  }

  // Check if target location is an empty square
  if (
    combatStore.combatGrid[targetLocation[0]][targetLocation[1]].type !==
    GridTypes.EmptyType
  ) {
    return false;
  }
  return true;
};

export const handleEnemyDeath = action((enemy) => {
  outputToChat(enemy.name + " has been slain!");
  combatStore.combatGrid[enemy.location[0]][enemy.location[1]] =
    new EmptyGrid();

  combatStore.enemies = removeItemFromArray(combatStore.enemies, enemy);
  combatStore.participants = removeItemFromArray(
    combatStore.participants,
    enemy
  );
  combatStore.turnOrder = removeTurnByActor(combatStore.turnOrder, enemy);

  enemy.deleteSelf();

  if (combatStore.enemies.length < 1) {
    let message =
      "You have defeated all the enemies. The combat window will now close.";
    endCombat(message);
  }
});

export function removeTurnByActor(turnOrderArray, enemyActor) {
  return turnOrderArray.filter((turnObject) => {
    return turnObject.actor !== enemyActor;
  });
}

export const fleeCombat = async () => {
  let enemyNamesText = "";
  combatStore.enemies.forEach((enemy, index) => {
    index === combatStore.enemies.length - 1
      ? (enemyNamesText += enemy.name + ".")
      : (enemyNamesText += enemy.name + ", ");
  });
  let remainingText =
    combatStore.enemies.length > 1
      ? "were " +
        combatStore.enemies.length +
        " enemies remaining. " +
        "The remaning enemies were: " +
        enemyNamesText
      : "was 1 enemy," + combatStore.enemies[0].name + ", remaining.";
  let message =
    "You have fled from the combat. There " +
    remainingText +
    " The combat window will now close.";

  endCombat(message);
};

export const endCombat = async (message) => {
  //Set inCombat to false before outputting message, so the combat summary is picked up for prompting.
  setInCombat(false);
  outputToChat(message);
  await wait(3000);
  setLoading(true);
  outputToChat("---Combat End---");
  //Reset player actions etc ready for next encounter
  playerStore.players.forEach((player) => {
    player.endOfTurn();
  });
  setShowCombatWindow(false);
  setCombatInfoReady(false);
  let newResponse = await tryGetDmResponse(await tryGeneratePrompt());
  outputToChat(newResponse);
  setLoading(false);
};

export default combatStore;
