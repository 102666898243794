import { observable, makeObservable, computed, action } from "mobx";
import { GridTypes } from "./GridTypes";
import { initiativeRoll, skillCheckRoll } from "./Rolls";
import {
  calculateAbilityScoreModifier,
  removeItemOnce,
} from "./GlobalFunctions";
import { CreatureSize, ProficencyType } from "./Rules";

//Lots to change here regarding how we generate stats and set them for a specific enemy.
//Currently there are lots of defaults set that we may want to pass (e.g from AI generated JSON)
class Enemy {
  type;
  name;
  HP;
  AC;
  location;
  abilityScores;
  actions;
  speed;
  remainingMovement;
  remainingActions;
  remainingBonusActions;
  remaningReactions;
  conditions;
  senses;
  skills;
  size;
  proficiencyBonus;
  remainingAttacks;
  attacks;
  MaxHP;

  constructor(
    name,
    ac = 10,
    maxHP = 10,
    aScores,
    speedArg,
    sizeArg,
    meleeWeapon,
    rangedWeapon,
    attacksPerTurn
  ) {
    this.type = GridTypes.EnemyType;
    this.name = name;
    this.MaxHP = maxHP;
    this.HP = this.MaxHP;
    this.AC = ac;

    //set the size based on passed size
    const normalizedSize =
      sizeArg.charAt(0).toUpperCase() + sizeArg.slice(1).toLowerCase();
    if (CreatureSize[normalizedSize] === undefined) {
      //Use medium as a default in case of error
      console.log("Creature size defaulted to medium");
      this.size = CreatureSize.Medium;
    } else {
      this.size = CreatureSize[normalizedSize];
    }

    //Would be nice to rework this into a name with x y as keys.
    this.location = [0, 0];
    this.proficiencyBonus = 1;
    this.abilityScores = {
      Strength: aScores.str,
      Dexterity: aScores.dex,
      Constitution: aScores.con,
      Intelligence: aScores.int,
      Wisdom: aScores.wis,
      Charisma: aScores.cha,
    };

    this.skills = {
      athletics: {
        proficency: ProficencyType.NonProficient,
        mod: this.StrMod,
      },
      acrobatics: {
        proficency: ProficencyType.NonProficient,
        mod: this.StrMod,
      },
      sleightOfHand: {
        proficency: ProficencyType.NonProficient,
        mod: this.DexMod,
      },
      stealth: { proficency: ProficencyType.NonProficient, mod: this.DexMod },
      arcana: { proficency: ProficencyType.NonProficient, mod: this.IntMod },
      history: { proficency: ProficencyType.NonProficient, mod: this.IntMod },
      investigation: {
        proficency: ProficencyType.NonProficient,
        mod: this.IntMod,
      },
      nature: { proficency: ProficencyType.NonProficient, mod: this.IntMod },
      religion: { proficency: ProficencyType.NonProficient, mod: this.IntMod },
      animalHandling: {
        proficency: ProficencyType.NonProficient,
        mod: this.WisMod,
      },
      insight: { proficency: ProficencyType.NonProficient, mod: this.WisMod },
      medicine: { proficency: ProficencyType.NonProficient, mod: this.WisMod },
      perception: {
        proficency: ProficencyType.NonProficient,
        mod: this.WisMod,
      },
      survival: { proficency: ProficencyType.NonProficient, mod: this.WisMod },
      deception: {
        proficency: ProficencyType.NonProficient,
        mod: this.ChaMod,
      },
      intimidation: {
        proficency: ProficencyType.NonProficient,
        mod: this.ChaMod,
      },
      performance: {
        proficency: ProficencyType.NonProficient,
        mod: this.ChaMod,
      },
      persuasion: {
        proficency: ProficencyType.NonProficient,
        mod: this.ChaMod,
      },
    };
    speedArg = speedArg.toString();
    const speedRegExp = /(\d+)\s*ft/;
    const speedMatch = speedArg.match(speedRegExp);
    if (speedMatch) {
      this.speed = parseInt(speedMatch[1]);
    } else {
      this.speed = parseInt(speedArg);
    }
    this.remainingMovement = this.speed;
    this.conditions = [];
    this.senses = [];
    this.attacksPerTurn = attacksPerTurn;
    this.remainingAttacks = this.attacksPerTurn;

    //We should always have one melee attack passed in constructor
    this.attacks = {
      meleeAttack: {
        name: meleeWeapon.name,
        bonusToHit: meleeWeapon.bonusToHit,
        damageRange: meleeWeapon.damageRange,
      },
    };
    //rangedWeapon passed in constructor when generating stats in combat.js
    if (rangedWeapon !== null) {
      this.attacks.rangedAttack = {
        name: rangedWeapon.name,
        bonusToHit: rangedWeapon.bonusToHit,
        damageRange: rangedWeapon.damageRange,
      };
    } else {
      this.attacks.rangedAttack = null;
    }

    makeObservable(this, {
      name: observable,
      MaxHP: observable,
      HP: observable,
      abilityScores: observable,
      StrMod: computed,
      DexMod: computed,
      ConMod: computed,
      IntMod: computed,
      WisMod: computed,
      ChaMod: computed,
      location: observable,
      speed: observable,
      remainingMovement: observable,
      conditions: observable,
    });
  }

  getName() {
    return this.name;
  }
  getHp() {
    return this.HP;
  }
  get StrMod() {
    return calculateAbilityScoreModifier(this.abilityScores.Strength);
  }
  get DexMod() {
    return calculateAbilityScoreModifier(this.abilityScores.Dexterity);
  }
  get ConMod() {
    return calculateAbilityScoreModifier(this.abilityScores.Constitution);
  }
  get IntMod() {
    return calculateAbilityScoreModifier(this.abilityScores.Intelligence);
  }
  get WisMod() {
    return calculateAbilityScoreModifier(this.abilityScores.Wisdom);
  }
  get ChaMod() {
    return calculateAbilityScoreModifier(this.abilityScores.Charisma);
  }
  getLocation() {
    return this.location;
  }

  setLocation(x, y) {
    this.location = [x, y];
  }

  useMovement = action((move) => {
    this.remainingMovement = this.remainingMovement - move;
  });

  rollInitiative() {
    return initiativeRoll(this);
  }

  //Process things to do with an end of turn. e.g reset currentMovement.
  //This is called from combat.js
  endOfTurn = action(() => {
    this.remainingMovement = this.speed;
    this.remainingActions = 1;
    this.remainingBonusActions = 1;
    this.remainingReactions = 1;
    this.remainingAttacks = this.attacksPerTurn;
  });
  reduceHp = action((value) => {
    this.HP = this.HP - value;
  });
  skillCheck(skillName) {
    let skill = this.skills[skillName];
    return skillCheckRoll(skill.mod, skill.proficency, this.proficiencyBonus);
  }
  addCondition = action((condition, duration = null) => {
    this.conditions.push({ condition, duration });
  });
  removeCondition = action((condition) => {
    removeItemOnce(this.conditions, condition);
  });
  //use 1 attack, and run passed function if it is passed
  useAttack = action((func = null) => {
    this.remainingAttacks--;
    if (func) {
      return func();
    }
  });
  //use 1 action, and run passed function if it is passed
  useAction = action((func = null) => {
    this.remainingActions--;
    if (func) {
      return func();
    }
  });
  //use 1 bonus action, and run passed function if it is passed
  useBonusAction = action((func = null) => {
    this.remainingBonusActions--;
    if (func) {
      return func();
    }
  });
  //use 1 reaction, and run passed function if it is passed
  useReaction = action((func = null) => {
    this.remainingReactions--;
    if (func) {
      return func();
    }
  });

  //At death, we can delete enemies
  deleteSelf() {
    delete this;
  }
}

export default Enemy;
