import React, { useRef } from "react";
import { useFrame } from "react-three-fiber";

const Icosahedron = () => {
  const meshRef = useRef();
  useFrame(() => {
    meshRef.current.rotation.x += 0.01;
    meshRef.current.rotation.y += 0.01;
  });

  return (
    <mesh ref={meshRef}>
      <icosahedronGeometry attach="geometry" args={[1, 0]} />
      <meshStandardMaterial
        attach="material"
        color="#e28743"
        opacity={0.8}
        transparent={true}
      />
    </mesh>
  );
};

export default Icosahedron;
